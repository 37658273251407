html, body, #root{
    height: 100%;
    background-color: #F9FAFD;
}

#root {
    display: flex;
    flex-direction: column;
}

#body {
    padding-top : 55px;
    color: #000;
}

.login-block {
    margin: auto;
    max-width: 500px;
    margin-top: 50vh;
    transform: translateY(-50%);
}

.input-group.group-password > .form-control {
    border-right: none;
}

.input-group.group-password > .form-control.is-invalid {
    padding-right: .75rem;
    background-image: none;
}

.input-group.group-password > .input-group-append > .btn {
    border: 1px solid #ced4da;
    border-left: none;
    border-radius: 0 .25rem .25rem 0;
    color: #6c757d;
}

.input-group.group-password > .input-group-append > .btn:focus {
    box-shadow: none;
}

.input-group.group-password > .form-control.is-invalid  + .input-group-append > .btn {
    border-color: #dc3545;
    color: #dc3545;
}

.pointer {
    cursor: pointer;
}

.bg-menu-left {
    background-color: #283644;
}

.nav-menu-left {
    position: fixed;
    min-width: 220px;
    max-width: 220px;
    height:100%;
    min-height: 100vh;
    margin-bottom: 0px;
    background-color: #283644;
    transition: margin .35s ease-in-out;
    flex-wrap: nowrap;
    margin-left: -220px;
    z-index: 10;
}

.nav-menu-left [data-toggle=collapse]:before {
    content: " ";
    border: solid;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 2px;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    position: absolute;
    top: 1.2rem;
    right: 1.25rem;
    transition: all .2s ease-out;
}

.nav-menu-left [aria-expanded=true]:before {
    -webkit-transform: rotate(-135deg);
    transform: rotate(-135deg);
    top: 1.3rem;
}

.nav-menu-left.toggle {
    margin-left: 0px;
}

.nav-menu-left .nav-link {
    padding: .75rem 1rem;
    position: relative;
}

.nav-menu-left > a, .nav-menu-left > a:focus, .nav-menu-left > a:active {
    color: #c7ced4;
    background-color: #283644;
}

.nav-menu-left-sub > a {
    color: #c7ced4;
    background-color: #263441;
}

.nav-menu-left > a:hover, .nav-menu-left > a.active, .nav-menu-left-sub > a:hover, .nav-menu-left-sub > a.active  {
    color: #ffffff;
    background-color: #212e38;
}

.navbar-header {
    position: fixed;
    width: 100%;
    z-index: 20;
    display: flex;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    border-bottom: 1px solid #dee2e6;
    background: white;
    box-shadow: 0 0.05rem 0.2rem rgba(0,0,0,.05);
}

.truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.h-46px {
    height: 46px;
}

.h-24px {
    height: 24px;
}

.text-title {
    font-size: 18px;
    margin-top: .5rem;
    margin-bottom: .5rem;
    font-weight: bold;
}

.rs-picker-daterange {
    width: 100%;
}

.rs-picker-toggle-value {
    color: #6c757d!important;
}

.rs-picker-toggle-clean {
    display: none!important;
}

.rs-picker-daterange-calendar-group {
    height: auto!important;
    min-width: 255px!important;
}

.rs-calendar {
    display: block!important;
}

.table td, .table th {
    border-top: none;
    border-bottom: 1px solid #dee2e6;
    vertical-align: middle;
}

.check-box {
    margin-top: .2rem;
}

.bold {
    font-weight: bold;
}

.max-w-400px {
    max-width: 400px;
}

.h-loding {
    height: calc(100vh - 150px);
}

.w-200px {
    width: 200px;
}

.w-160px {
    width: 160px;
}

.w-60px {
    width: 60px;
}

.w-100px {
    width: 100px;
}

.w-auto-gallery {
    width: calc((100% - 280px));
    min-width: 200px;
}

.w-auto-divide-2 {
    width: calc((100% - 480px) / 2);
    min-width: 200px;
}

.w-auto-divide-3 {
    width: calc((100% - 380px) / 3);
    min-width: 200px;
}

.w-auto-divide-team {
    width: calc((100% - 380px) / 2);
    min-width: 200px;
}

.text-nowarp {
    white-space: nowrap;
}

tr.tr-td-bottom-0 > td {
    border-bottom: none!important;
}

.ck-editor__editable {
    min-height: 150px;
}

.onoffswitch {
    position: relative; width: 80px;
    -webkit-user-select:none; -moz-user-select:none; -ms-user-select: none;
}

.onoffswitch-checkbox {
    position: absolute;
    opacity: 0;
    pointer-events: none;
}

.onoffswitch-label {
    display: block; overflow: hidden; cursor: pointer;
    border: 2px solid #DEE2E6; border-radius: 20px;
}

.onoffswitch-inner {
    display: block; width: 200%; margin-left: -100%;
    transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
    display: block; float: left; width: 50%; height: 30px; padding: 0; line-height: 30px;
    font-size: 14px; color: white; font-family: Trebuchet, Arial, sans-serif; font-weight: bold;
    box-sizing: border-box;
}

.onoffswitch-inner:before {
    content: "เปิด";
    padding-left: 10px;
    background-color: #007BFF; color: #FFFFFF;
}

.onoffswitch-inner:after {
    content: "ปิด";
    padding-right: 10px;
    background-color: #EEEEEE; color: #999999;
    text-align: right;
}

.onoffswitch-switch {
    display: block; width: 17px; margin: 6.5px;
    background: #FFFFFF;
    position: absolute; top: 0; bottom: 0;
    right: 46px;
    border: 2px solid #DEE2E6; border-radius: 20px;
    transition: all 0.3s ease-in 0s; 
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
    margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
    right: 0px; 
}

.select-search {
    text-align: left;
}

.select-search.dropdown-toggle::after {
    position: absolute;
    right: 10px;
    top: 17px;
}

.select-search.dropdown-toggle.form-control-sm::after {
    top: 15px;
}

.bg-gray {
    background-color: #ececec;
}

@media (min-width: 540px){
    .rs-picker-daterange-calendar-group {
        min-width: 510px!important;
    }

    .rs-calendar {
        height: 274px!important;
        display: inline-block!important;
    }
}

@media (min-width: 769px){
    #body {
        padding-top : 5px;
    }

    .nav-menu-left {
        position: relative;
    }

    .navbar-header {
        position: relative;
    }
}